<template>
  <div>
    <h1>Adicionar Insumo</h1>
    <v-form ref="formCreateFuel" @submit.prevent="save()">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            label="Nome"
            v-model="fuel.name"
            :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Preço"
            v-model="fuel.price"
            :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn color="secondary" :loading="saving" class="mt-2" type="submit"
            >Salvar</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import http from "@/modules/http";
export default {
  data() {
    return {
      fuel: {
        name: "",
        price: "",
      },
      saving: false,
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
    };
  },
  methods: {
    cleanForm() {
      this.fuel = {
        name: "",
        price: "",
      };
    },
    save() {
      if (this.$refs.formCreateFuel.validate()) {
        this.saving = true;
        http
          .post("api/fuels", this.fuel)
          .then((res) => {
            this.$side({
              type: "success",
              msg: "Salvo com sucesso",
              duration: 4000,
            });
            this.cleanForm();
						this.$refs.formCreateFuel.reset();
            this.saving = false;
            this.$emit("fuelSaved", true);
          })
          .catch((err) => {
            console.log(err);
            this.saving = false;
          });
      }
    },
  },
};
</script>