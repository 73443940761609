<template>
  <div>
    <create-insumo @fuelSaved="getFuels()" />
    <h1>Insumos</h1>

    <v-btn
      color="secondary"
      class="mx-1 my-2"
      @click="getFuels()"
      :loading="loading"
    >
      <v-icon> mdi-refresh </v-icon>
    </v-btn>

    <v-data-table :loading="loading" :items="fuels" :headers="headers">
      <template v-slot:[`item.price`]="{ item }">
        <span>{{ item.price ? $functions.moneyFormat(item.price) : "" }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="openEditModal(item)" color="warning">
          mdi-pencil
        </v-icon>
        <v-icon
          @click="deleteFuel(item)"
          color="error"
          :disabled="!deleting ? false : true"
        >
          {{ !deleting ? "mdi-delete" : "mdi-progress-close" }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog persistent v-model="editModal">
      <edit-fuel
        v-if="editModal"
        @cancelUpdate="handleEditFuel()"
        @fuelUpdated="handleEditFuel()"
        :propFuel="clickedFuel"
      />
    </v-dialog>
  </div>
</template>

<script>
import http from "@/modules/http";
import CreateInsumo from "../components/Create.vue";
import EditFuel from "../components/EditFuel.vue";
export default {
  components: {
    CreateInsumo,
    EditFuel,
  },
  data() {
    return {
      fuels: [],
      clickedFuel: {},
      headers: [
        { text: "Nome", value: "name" },
        { text: "Preço", value: "price" },
        { text: "Ações", value: "actions" },
      ],
      loading: false,
      deleting: false,
      editModal: false,
    };
  },
  methods: {
    handleEditFuel() {
      this.editModal = false;
      this.getFuels();
    },
    openEditModal(fuel) {
      this.clickedFuel = fuel;
      this.editModal = true;
    },
    deleteFuel(fuel) {
      this.$confirm({
        type: "error",
        title: "Remover Insumo",
        content: "Tem certeza que deseja excluir este insumo?",
        confirmText: "Sim",
        cancelText: "Não",
        confirm: () => {
          this.deleting = true;
          http
            .delete("api/fuels/" + fuel.id)
            .then((res) => {
              this.deleting = false;
              this.$side({
                type: "success",
                msg: "Removido com sucesso",
                duration: 4000,
              });
              this.getFuels();
            })
            .catch((err) => {
              this.deleting = false;
              console.log(err.response.data);

              this.$side({
                type: "error",
                msg: err.response.data.msg,
                duration: 5000,
              });
            });
          this.$close(); //If you don't want to close the dialog, you can not use it.
        },
        cancel: () => {
          console.log("Your callback");
          this.$close(); //If you don't want to close the dialog, you can not use it.
        },
      });
    },
    getFuels() {
      this.loading = true;
      http
        .get("api/fuels")
        .then((res) => {
          this.loading = false;
          this.fuels = res.data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  mounted() {
    this.getFuels();
  },
};
</script>