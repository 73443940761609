<template>
  <v-card>
    <v-card-title>Editar Insumo</v-card-title>
    <v-card-text>
      <v-form ref="formUpdateFuel" @submit.prevent="updateFuel()">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              label="Nome"
              v-model="fuel.name"
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Preço"
              v-model="fuel.price"
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="12" md="2">
            <v-btn
              color="secondary"
              class="ma-2"
              type="submit"
              :loading="saving"
              >Salvar</v-btn
            >
            <v-btn
              color="error"
              class="ma-2"
              @click="$emit('cancelUpdate', true)"
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import http from "@/modules/http";
export default {
  props: {
    propFuel: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.fuel = this.propFuel;
  },
  data() {
    return {
      fuel: {},
      saving: false,
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
    };
  },
  methods: {
    updateFuel() {
      if (this.$refs.formUpdateFuel.validate()) {
        this.saving = true;
        http
          .put("api/fuels/" + this.fuel.id, this.fuel)
          .then((res) => {
            this.$side({
              type: "success",
              msg: "Salvo com sucesso aqui",
              duration: 4000,
            });
            this.saving = false;
            this.$emit("fuelUpdated", true);
            this.$refs.formUpdateFuel.reset();
          })
          .catch((err) => {
            console.log(err);
            this.saving = false;
          });
      }
    },
  },
};
</script>